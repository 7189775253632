import { Notification } from '@app/components'
import { IStore } from '@app/redux'
import { auth, errorHandler } from '@app/services'
import axios from 'axios'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export const EntityContext = createContext({
  entityInfo: null as {
    companyName: any
    phoneNumber: any
    email: any
  },
  fetchEntity: null as any,
})

export const EntityProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useSelector((state: IStore) => state)

  //const [wallets, setWallets] = useState([])
  const [entityInfo, setEntityInfo] = useState({
    companyName: '',
    phoneNumber: '',
    email: '',
  })

  const fetchEntity = async () => {
    if (!user?.selectedEntityName || !auth.currentUser) return
    try {
      const params = { businessIdentityId: user.selectedEntityIdentityId, profile: true }
      const authorization = await auth.currentUser.getIdToken()
      const headers = {
        Authorization: authorization,
      }
      const res = await axios.get('/api/fortress/create-entity-account', { params, headers })

      setEntityInfo({
        companyName: res.data.business.companyName,
        phoneNumber: res.data.business.phone,
        email: res.data.business.email,
      })
    } catch (error) {
      Notification({ message: error.message, type: 'error' })
      errorHandler.report`Failed to fetch entity profile: ${error}`
    }
  }

  useEffect(() => {
    fetchEntity()
  }, [user.selectedEntityName])

  const value = useMemo(
    () => ({
      entityInfo,
      fetchEntity,
    }),
    [entityInfo, fetchEntity],
  )

  return <EntityContext.Provider value={value}> {children} </EntityContext.Provider>
}
