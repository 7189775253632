import { IStore } from '@app/redux'
import { auth, errorHandler } from '@app/services'
import axios from 'axios'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export const BankAccountsContext = createContext({
  accounts: null as any[],
  setAccounts: null as React.Dispatch<React.SetStateAction<any[]>>,
  bankAccounts: null as any[],
  setBankAccounts: null as React.Dispatch<React.SetStateAction<any[]>>,
  ogbAccounts: null as any[],
  setOGBAccounts: null as React.Dispatch<React.SetStateAction<any[]>>,
  cardAccounts: null as any[],
  setCardAccounts: null as React.Dispatch<React.SetStateAction<any[]>>,
  wireAccounts: null as any[],
  setWireAccounts: null as React.Dispatch<React.SetStateAction<any[]>>,
  fetchAccounts: null as () => Promise<void>,
})

export const BankAccountsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useSelector((state: IStore) => state)

  //const [wallets, setWallets] = useState([])
  const [accounts, setAccounts] = useState([])
  const [bankAccounts, setBankAccounts] = useState([])
  const [ogbAccounts, setOGBAccounts] = useState([])
  const [cardAccounts, setCardAccounts] = useState([])
  const [wireAccounts, setWireAccounts] = useState([])

  const fetchAccounts = async () => {
    if (user?.registrationStepNo != 4) return
    try {
      //const identity = user?.selectedEntityIdentityId ? user?.selectedEntityIdentityId : user?.fortressIdentityId
      //const custodial = user?.selectedEntityCustodialId ? user?.selectedEntityCustodialId : user?.fortressCustodialId

      const params = { identityId: null, custodialId: null }
      const authorization = await auth.currentUser.getIdToken()
      const headers = {
        Authorization: authorization,
      }
      const res = await axios.get('/api/north-one/bank-accounts', { params, headers })

      setAccounts(
        res.data.bankAccounts.filter((item) => {
          return (item['type'] === 'ach' || item['type'] === 'wire' || item['type'] === 'ogb') && !item['hidden']
        }),
      )
      setBankAccounts(res.data.bankAccounts.filter((item) => item['type'] === 'ach' && !item['hidden']))
      setOGBAccounts(res.data.bankAccounts.filter((item) => item['type'] === 'ogb' && !item['hidden']))
      setWireAccounts(res.data.bankAccounts.filter((item) => item['type'] === 'wire' && !item['hidden']))
    } catch (error) {
      //   Notification({ message: error.message, type: 'error' })
      errorHandler.report`Failed to fetch bank accounts: ${error}`
    }
  }

  useEffect(() => {
    fetchAccounts()
  }, [user?.fortressIdentityId, user?.fortressCustodialId, user?.selectedEntityName])

  const value = useMemo(
    () => ({
      accounts,
      setAccounts,
      bankAccounts,
      setBankAccounts,
      ogbAccounts,
      setOGBAccounts,
      cardAccounts,
      setCardAccounts,
      wireAccounts,
      setWireAccounts,
      fetchAccounts,
    }),
    [
      accounts,
      setAccounts,
      bankAccounts,
      setBankAccounts,
      ogbAccounts,
      setOGBAccounts,
      cardAccounts,
      setCardAccounts,
      wireAccounts,
      setWireAccounts,
      fetchAccounts,
    ],
  )

  return <BankAccountsContext.Provider value={value}> {children} </BankAccountsContext.Provider>
}
