import { Notification } from '@app/components'
import { IStore } from '@app/redux'
import { auth, errorHandler, getEntity, getSingle } from '@app/services'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export const BalanceContext = createContext({
  balance: null as number,
  cardHold: null as number,
  collateral: null as number,
  setBalance: null as React.Dispatch<React.SetStateAction<number>>,
  fetchAccounts: null as () => Promise<void>,
})

export const BalanceProvider: React.FC<React.PropsWithChildren<{ userLoading: boolean }>> = ({
  children,
  userLoading,
}) => {
  const { user } = useSelector((state: IStore) => state)

  const [collateral, setCollateral] = useState(0)
  const [balance, setBalance] = useState(0)
  const [cardHold, setCardHold] = useState(0)

  const fetchAccounts = async () => {
    if (userLoading) return
    if (user?.registrationStepNo != 4 || !auth.currentUser) return
    try {
      //const identity = user?.selectedEntityIdentityId ? user?.selectedEntityIdentityId : user?.fortressIdentityId
      //const custodial = user?.selectedEntityCustodialId ? user?.selectedEntityCustodialId : user?.fortressCustodialId

      /*const params = { identityId: identity, custodialId: custodial }
      const authorization = await auth.currentUser.getIdToken()
      const headers = {
        Authorization: authorization,
      }
      const res = await axios.get('/api/fortress/accounts', { params, headers })
      */
      const userDoc = await getSingle(user.uid)
      const entityDoc = user.selectedEntityCustodialId ? await getEntity(user.selectedEntityCustodialId) : undefined

      const achHeld = user.selectedEntityCustodialId
        ? entityDoc.achHold
          ? entityDoc.achHold
          : 0
        : userDoc.achHold == undefined
          ? 0
          : userDoc.achHold
      const cardHeld = user.selectedEntityCustodialId
        ? entityDoc.cardHold
          ? entityDoc.cardHold
          : 0
        : userDoc.cardHold == undefined
          ? 0
          : userDoc.cardHold

      const moved = userDoc.USD_Moved ? userDoc.USD_Moved : 0

      const userUSD = userDoc.USD_Balance + moved

      const collateralUSD = 0

      setBalance(Math.max(userUSD - achHeld - cardHeld, 0))
      setCollateral(
        collateralUSD > 0
          ? collateralUSD +
              parseFloat(process.env.NEXT_PUBLIC_RECOVERY_FUND) +
              parseFloat(process.env.NEXT_PUBLIC_RESERVE_FUND)
          : 0,
      )
      setCardHold(cardHeld)
    } catch (error) {
      Notification({ message: error.message, type: 'error' })
      errorHandler.report`Failed to fetch balance accounts: ${error}`
    }
  }

  useEffect(() => {
    fetchAccounts()
  }, [userLoading, user?.selectedEntityName])

  const value = useMemo(
    () => ({
      balance,
      cardHold,
      collateral,
      setBalance,
      fetchAccounts,
    }),
    [balance, cardHold, setBalance, fetchAccounts],
  )

  return <BalanceContext.Provider value={value}> {children} </BalanceContext.Provider>
}
